
import './carrousel.scss';
import React, { useState } from 'react';
import afrique from '../../asset/img/afrique.jpg'
import egypte from '../../asset/img/egypte2.jpg'
import ecosse from '../../asset/img/ecosse.jpg'
import canada from '../../asset/img/canada.jpg'
import islande from '../../asset/img/islande2.jpg'
import tanzanie from '../../asset/img/tanz2.JPG'
import costa from '../../asset/img/costa.jpg'
import { Menubar } from 'primereact/menubar';

const Carrousel = () => {

  const [imgCarrousel, setImgCarrousel] = useState([afrique]);
  const [labelMenu, setLabel] = useState(['Afrique du sud']);

  const itemsBar = [
    {
      label: 'Afrique du sud',
      command: () => {
        setImgCarrousel(afrique);
        setLabel('Afrique du sud');
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Canada',
      command: () => {
        setImgCarrousel(canada);
        setLabel('Canada');
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Egypte',
      command: () => {
        setLabel('Egypte');
        setImgCarrousel(egypte);
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Ecosse',
      command: () => {
        setLabel('Ecosse');
        setImgCarrousel(ecosse);
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Islande',
      command: () => {
        setLabel('Islande');
        setImgCarrousel(islande);
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Costa Rica',
      command: () => {
        setLabel('Costa Rica');
        setImgCarrousel(costa);
        console.log(imgCarrousel);
      }
    },
    {
      label: 'Tanzanie',
      command: () => {
        setImgCarrousel(tanzanie);
        setLabel('Tanzanie');
        console.log(imgCarrousel);
      }
    }
  ];


  return (
    <div class="container">
      <div>
        <img class="top-image" src={imgCarrousel} alt="afrique" />
        <div class="centered">{labelMenu}</div>
        <Menubar className='bar-menu menu fill' model={itemsBar} />
      </div>

    </div>
  );
}

export default Carrousel;