import "./App.css";
import Header from "./component/header/Header";
import Carrousel from "./component/carrousel/carrousel";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TrenteAns from "./component/trenteAns/trenteAns";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
       
          <Switch>
            <Route path="/30ans">
              <TrenteAns />
            </Route>
            <Route path="/">
              <Carrousel />
            </Route>
          </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
