import "./trenteAns.scss";
import React, { useState } from "react";
import teletubbies from "../../asset/img/teletubbies.jpg";
import franklin from "../../asset/img/franklin.jpg";
import pokemon from "../../asset/img/pokemon.jpg";
import bus from "../../asset/img/bus.jpg";
import bravo from "../../asset/img/bravo.jpeg";
import michat from "../../asset/img/michat.jpeg";
import cour from "../../asset/img/cour.jpg";
import spies from "../../asset/img/spies.jpg";
import nana from "../../asset/img/nana.jpg";
import dexter from "../../asset/img/dexter.jpg";
import dbz from "../../asset/img/dbz.jpg";
import simpson from "../../asset/img/simpson.jpg";
import Bob from "../../asset/img/bob.jpg";
import pierrafeu from "../../asset/img/pierrafeu.jpg";
import delajungle from "../../asset/img/delajungle.jpg";
import kim from "../../asset/img/scale.png";
import arnold from "../../asset/img/arnoldjpg.jpg";
import trotue from "../../asset/img/tortue.png";
import south from "../../asset/img/south.jpg";
import zinzins from "../../asset/img/zinzin.jpg";
import marsupilami from "../../asset/img/marsupilami.jpg";
import razmoket from "../../asset/img/razmoket.jpg";
import power from "../../asset/img/power.jpg";
import sabrina from "../../asset/img/sabrina_.jpg";
import denfer from "../../asset/img/denfer.jpg";
import titeuf from "../../asset/img/titeuf.jpg";
import cedric from "../../asset/img/cedric.png";
import sophie from "../../asset/img/sophie.jpg";
import jumanji from "../../asset/img/jumanji.jpeg";
import ginger from "../../asset/img/ginger.jpg";
import inspecteur from "../../asset/img/inspecteur.jpg";
import doo from "../../asset/img/doo.jpg";
import midi from "../../asset/img/midi.jpeg";
import fois from "../../asset/img/fois.jpg";
import babar from "../../asset/img/babar.jpg";
import minus from "../../asset/img/minus.jpg";
import loney from "../../asset/img/loney.jpg";
import tom from "../../asset/img/tom.jpg";
import castor from "../../asset/img/castor.jpg";
import cleo from "../../asset/img/cleo.png";
import ed from "../../asset/img/ed.jpg";
import courage from "../../asset/img/courage.jpg";
import allume from "../../asset/img/allume.jpg";
import tomtom from "../../asset/img/tomtom.jpg";
import titi from "../../asset/img/titi.jpeg";
import denver from "../../asset/img/denver.jpg";
import oggy from "../../asset/img/oggy.jpg";
import winx from "../../asset/img/winx.jpg";
import toupou from "../../asset/img/toupou.jpg";
import arthur from "../../asset/img/arthur.jpg";
import orga from "../../asset/img/orga.jpg";
import { Image } from "primereact/image";

const TrenteAns = () => {
  let days = 0;

  // Définir la date de fin du compte à rebours (dans 10 secondes)
  const countDownDate = new Date("05/04/2024").getTime();
  console.log("countDownDate", countDownDate);
  // Mettre à jour le compte à rebours toutes les secondes

  // Obtenir le temps restant
  const now = new Date().getTime();
  const distance = countDownDate - now;

  // Calculer les jours, heures, minutes et secondes restantes
  days = Math.floor(distance / (1000 * 60 * 60 * 24));

  // Afficher le compte à rebours dans la console
  console.log(`${days}j`);

  // Si le compte à rebours est terminé, arrêter l'intervalle

  return (
    <div className="annive">
      <div className="title-30">Anniversaire Claire, 30 ans</div>
      <div className="jourj">
        <div className="jour"><span>J-{`${days}`}</span> </div>
      </div>
      <div className="title-30">4 mai 2024</div>
      <Image className="dessin" src={orga} alt="Image1" width="70%" preview />
      <div className="">
        <div className="title-20">
          Inscription:{" "}
          <a href="https://forms.gle/ttLiTpNFoMRkVrNB6" target="_blank">
            Formulaire
          </a>
          <br />
          Thème du déguisement: Dessins animés des années 1990 <br />
          <div className="rowrow">
            <div className="colcol">
              <div>
                Teletubbies
                <br />
                <Image
                  className="dessin"
                  src={teletubbies}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Franklin
                <br />
                <Image
                  className="dessin"
                  src={franklin}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Pokemon
                <br />
                <Image
                  className="dessin"
                  src={pokemon}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Bus magique
                <br />
                <Image className="dessin" src={bus} alt="Image1" width="80%" />
                <br />
                Johnny bravo
                <br />
                <Image
                  className="dessin"
                  src={bravo}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Michat michien
                <br />
                <Image
                  className="dessin"
                  src={michat}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Totalies spies
                <br />
                <Image
                  className="dessin"
                  src={spies}
                  alt="Image1"
                  width="80%"
                />
                <br />
                La cour de récré
                <br />
                <Image className="dessin" src={cour} alt="Image1" width="80%" />
                <br />
                Les super nanas
                <br />
                <Image className="dessin" src={nana} alt="Image1" width="80%" />
                <br />
                Le laboratoire de dexter
                <br />
                <Image
                  className="dessin"
                  src={dexter}
                  alt="Image1"
                  width="80%"
                />
                <br />
                DBZ
                <br />{" "}
                <Image className="dessin" src={dbz} alt="Image1" width="80%" />
                <br /> Les Simpson
                <br />
                <Image
                  className="dessin"
                  src={simpson}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Bob l’eponge
                <br />
                <Image className="dessin" src={Bob} alt="Image1" width="80%" />
                <br />
                Famille pierrafeu
                <br />
                <Image
                  className="dessin"
                  src={pierrafeu}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Famille delajungle
                <br />
                <Image
                  className="dessin"
                  src={delajungle}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Kim possible
                <br />
                <Image className="dessin" src={kim} alt="Image1" width="80%" />
                <br />
                Hey arnold
                <br />
                <Image
                  className="dessin"
                  src={arnold}
                  alt="Image1"
                  width="80%"
                />
                <br />
              </div>
            </div>
            <div className="colcol">
              <div>
                Tortue ninja
                <br />
                <Image
                  className="dessin"
                  src={trotue}
                  alt="Image1"
                  width="80%"
                />
                <br />
                South park
                <br />
                <Image
                  className="dessin"
                  src={south}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Les zinzins de l’espace
                <br />
                <Image
                  className="dessin"
                  src={zinzins}
                  alt="Image1"
                  width="80%"
                />
                <br />
                inspecteur
                <br />
                <Image
                  className="dessin"
                  src={inspecteur}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Marsupilami
                <br />
                <Image
                  className="dessin"
                  src={marsupilami}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Les razmoket
                <br />
                <Image
                  className="dessin"
                  src={razmoket}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Rocket power
                <br />
                <Image
                  className="dessin"
                  src={power}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Sourire d’enfer
                <br />
                <Image
                  className="dessin"
                  src={denfer}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Titeuf
                <br />
                <Image
                  className="dessin"
                  src={titeuf}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Cedric
                <br />
                <Image
                  className="dessin"
                  src={cedric}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Les malheurs de Sophie
                <br />
                <Image
                  className="dessin"
                  src={sophie}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Jumanji
                <br />
                <Image
                  className="dessin"
                  src={jumanji}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Ginger
                <br />
                <Image
                  className="dessin"
                  src={ginger}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Inspecteur gadget
                <br />
                <Image
                  className="dessin"
                  src={inspecteur}
                  alt="Image1"
                  width="80%"
                />
                <br />
                scooby-doo
                <br />
                <Image className="dessin" src={doo} alt="Image1" width="80%" />
                <br />
                Midi les zouzous
                <br />
                <Image className="dessin" src={midi} alt="Image1" width="80%" />
                <br />
                Il était une fois la vie
                <br />
                <Image className="dessin" src={fois} alt="Image1" width="80%" />
                <br />
                Babar
                <br />
                <Image
                  className="dessin"
                  src={babar}
                  alt="Image1"
                  width="80%"
                />
                <br />
              </div>
            </div>
            <div className="colcol">
              <div>
                Minus et cortex
                <br />
                <Image
                  className="dessin"
                  src={minus}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Les looney tunes
                <br />
                <Image
                  className="dessin"
                  src={loney}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Tom et jerry
                <br />
                <Image className="dessin" src={tom} alt="Image1" width="80%" />
                <br />
                Père castor
                <br />
                <Image
                  className="dessin"
                  src={castor}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Cléo et Chico
                <br />
                <Image className="dessin" src={cleo} alt="Image1" width="80%" />
                <br />
                Courage, le chien froussard
                <br />
                <Image
                  className="dessin"
                  src={courage}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Les castors allumés
                <br />
                <Image
                  className="dessin"
                  src={allume}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Tom-tom et Nana
                <br />
                <Image
                  className="dessin"
                  src={tomtom}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Titi et grominet
                <br />
                <Image className="dessin" src={titi} alt="Image1" width="80%" />
                <br />
                Denver le dernier dinosaure
                <br />
                <Image
                  className="dessin"
                  src={denver}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Oggy et les cafards
                <br />
                <Image className="dessin" src={oggy} alt="Image1" width="80%" />
                <br />
                Les winx
                <br />
                <Image className="dessin" src={winx} alt="Image1" width="80%" />
                <br />
                Toupou
                <br />
                <Image
                  className="dessin"
                  src={toupou}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Arthur
                <br />
                <Image
                  className="dessin"
                  src={arthur}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Sabrina <br />
                <Image
                  className="dessin"
                  src={sabrina}
                  alt="Image1"
                  width="80%"
                />
                <br />
                Ed, Edd et Eddy <br />
                <Image className="dessin" src={ed} alt="Image1" width="80%" />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      v1.0.5
    </div>
  );
};

export default TrenteAns;
